import { AppProps } from "next/app";
import React from "react";
import Head from "next/head";

import "styles/globals.scss";
import "styles/jwplayer.scss";

function MyApp({ Component, pageProps }: AppProps) {
    return (
        <>
            <Head>
                {/* charset|viewport|http-equiv */}
                <meta key="viewport" name="viewport" content="width=device-width, initial-scale=1" />
            </Head>
            <Component {...pageProps} />
        </>
    );
}

export default MyApp;
